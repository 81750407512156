<template>
    <div style="margin-bottom: 0;">
        <slot>
            <el-button plain icon="el-icon-menu" style="padding:8px;"><i class="el-icon-arrow-down icon"></i></el-button>
        </slot>
        <div @click.stop v-if="drop_show" class="check_view_49">
            <el-checkbox-group v-model="selected" @change="$emit('change',selected)">
                <el-checkbox :label="item" v-for="(item, index) in list" :key="index"></el-checkbox>
            </el-checkbox-group>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {},
        checked: {}
    },
    data() {
        return {
            selected: [],
            drop_show: false
        }
    },
    watch: {
        checked: {
            handler(newVal) {
                this.selected = newVal
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        // document.removeEventListener("click")
        document.addEventListener("click", () => {
            this.drop_show = false;
            this.$forceUpdate()
        });
    },
    destroyed() {
        // document.removeEventListener("click")
    },
    methods: {
        trigger() {
            this.drop_show = !this.drop_show;
            this.$forceUpdate()
        }
    }
}
</script>
<style lang="scss" scoped>
.el-checkbox {
    // display: block !important;
    // margin-right: 0 !important;
    // z-index: 100 !important;
    // position: relative;
    // >*{
    //     position: relative;
    //     z-index: 100;
    // }
    padding: 5px 0;
    // pointer-events: auto;

}

:deep() .el-checkbox-group {
    min-width: 100px !important;
    // position: ;
    position: fixed;
    background-color: #fff;
    // z-index: 100000;
    z-index: 5;
    padding: 10px;
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 1px solid #DCDCDC;
    // left: -20px;
    transform: translate(-10px, 0);
    text-align: left;
    >*{
        display: block !important;
    }
}
</style>