import axios from "axios";
import Qs from 'qs';
import {
	Base64
} from 'js-base64';
import {Message} from 'element-ui';
import router from '../router';

let baseURL = process.env.VUE_APP_DEV_REQUEST_DOMAIN_PREFIX;

const instance = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    // withCredentials: true,
});

// http request 请求拦截器
instance.interceptors.request.use(
    config => {
        // const token = localStorage.getItem('token');
        // config.headers.authToken = token ? token : "";
        // const userId = localStorage.getItem('userId');
        // config.headers.userId = userId ? userId : "";

        return config;
    },
    err => {
        return Promise.reject(err);
    }
)

let routerTimer = null;
// http response 响应拦截器
instance.interceptors.response.use(
    response => {
        if (response.status === 200) {
			// 登录超时
			if(typeof response.data == 'object' && response.data.hasOwnProperty("code") && response.data.code == 40001){
				clearTimeout(routerTimer);
				Message.warning(response.data.msg);
				// this.$router.push({path:'/'})
				// return
				localStorage.setItem('loginOverdue', 'yes')
				localStorage.removeItem('token')				
				localStorage.removeItem('userType')
				routerTimer = setTimeout(function(){
					router.push({
						path:'/',
						name: "登录页"
					});
				}, 500)
				
			// 报错
			}else if(typeof response.data == 'object' && response.data.hasOwnProperty("code") && response.data.code != 0){
				if(!response.config.errorUnShow){
					Message.warning(response.data.msg);
				}
                return Promise.reject(response);
            }
            return Promise.resolve(response);

        } else {
			Message.warning("请求错误，请重试");
            return Promise.reject(response);
        }
    },
    error => {
		Message.warning("服务器繁忙，请稍后");
        return Promise.reject(error)
    }
)

export default function(options = {}) {
	if (!options.method) {
		options.method = "get"
	}
	if (!options.params) {
		options.params = {}
	}
	if (!options.headers) {
		options.headers = {
			"Content-Type": "application/json;"
		}
	}
	if (options.method == "post") {
		options.headers = {
			...options.headers,
			"Content-Type": "application/json;"
			// 'Content-Type': 'application/x-www-form-urlencoded' 
		}
	}
	const token = localStorage.getItem("token");
	// console.log(token);
	if (token) {
		options.headers = {
			...options.headers,
			Token: `Bearer ${token}`
		}
	}
	const clientId = process.env.VUE_APP_CLIENT_ID;
	const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
	options.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`;
	options.headers['Tenant'] = localStorage.getItem("tenant");

	let axios_options = {
		...options,
		//请求使用的方法，可写get或者post等
		method: options.method,
		//url写的是请求地址
		// url: `http://172.10.10.13:8764/${options.url}`,
		url: `${process.env.VUE_APP_DEV_REQUEST_DOMAIN_PREFIX}/${options.url}`,
		//params写的是数据请求条件 ，即后端给定的请求参数中，是否必须  是true的选项必须写在params内
		// data: Qs.stringify(options.params),
		headers: options.headers
	};

	if (options.method == "get") {
		axios_options.params = options.params
	} else if (options.method == "post" || options.method == "put") {
		axios.defaults.headers['content-Type'] = 'application/json'
		axios_options.data = JSON.stringify(options.params)
		// 既然有data了，删掉params，不然所有请求都带着url参数
		delete axios_options.params;
		// axios_options.data=Qs.stringify(options.params)
		// axios_options.data = Qs.stringify(options.params)
		
	}

    return new Promise((resolve, reject) => {
		instance(axios_options).then(res => {
			resolve(res)
			if (res&&options.success) {
				options.success(res)
			}
			if(options.complete){
				options.complete(res)
			}
		}).catch(fail => {
			if (options.fail) {
				options.fail(fail)
			}
			if(options.complete){
				options.complete(fail)
			}
		})
    })
	/* axios(axios_options).then(res => {

		if (options.success) {
			options.success(res)
		}
	}).catch(fail => {
		if (options.fail) {
			options.fail(fail)
		}
	}) */
}

// import Axios from 'axios'
// class Request {
// 	request(url, data, method, isAuth, header) {
// 		header = isAuth ? {
// 			token,
// 			...header
// 		} : header
// 		return new Promise((resolve, reject) => {
// 			let option = {
// 				method, 
// 				url
// 			}
// 			if (method === "get") {
// 				option.params = {
// 					...data
// 				}
// 			} else {
// 				option.data = {
// 					...data
// 				}
// 			}
// 			Axios(option).then(res => {
// 				resolve(res.data)
// 			}).catch(() => {
// 				reject()
// 			})
// 		})
// 	}
// 	get(url, data) {
// 		return this.request(url, data, 'get')
// 	}
// 	post(url, data) {
// 		return this.request(url, data, 'post')
// 	}
// }
// export default new Request()