<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>

	import "@/assets/font/Medium.ttf"
	export default{
    mounted() {
      this.limitationUserFun()
    },
    methods: {
      limitationUserFun() {
        document.οncοntextmenu=function(){return false;}; 
        document.onselectstart=function(){return false;};
        let h = window.innerHeight;
        let w = window.innerWidth;
        //禁用右键
        document.oncontextmenu = function () { return false; };
        //在本网页的任何键盘敲击事件都是无效操作 （防止F12和shift+ctrl+i调起开发者工具）  
        // window.onkeydown = window.onkeyup = window.onkeypress = function () {
        //     window.event.returnValue = false;
        //     return false;
        // }
        //禁用开发者工具F12
        document.onkeydown = function () {
            if (window.event && window.event.keyCode == 123) {
                event.keyCode = 0;
                event.returnValue = false;
                return false;
            }
        };
        //如果用户在工具栏调起开发者工具，那么判断浏览器的可视高度和可视宽度是否有改变，如有改变则关闭本页面  
        // window.onresize = function () {
        //     if (h != window.innerHeight || w != window.innerWidth) {
        //       window.location = "about:blank";
        //       window.opener=null;
        //       window.open('',self);
        //       window.close();
        //       window.close();
        //     }
        // }
        document.onkeydown = () => {
          //禁用F12
          if (window.event && window.event.keyCode == 123) {
            return false;
          //禁用ctrl+shift+i,
          } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
            return false;
          //屏蔽Shift+F10
          } else if (window.event.shiftKey && window.event.keyCode == 121) {
            return false;
          }
        };
      }
    }
  }
</script>

<style>
@font-face {
	font-family: "Medium";
	src: url(~@/assets/font/Medium.ttf) format("truetype");
}
body {
	font-family: "Medium"
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}
.pageWidth{
  width: 62.5vw;
  margin: 0 auto;
}

*{
	margin: 0;
	padding: 0;
  
}
body{
  font-family: PingFangSC;
  /* word-break: break-word; */
  width:100%;
}
a{
	text-decoration: none;	
}
div{
	box-sizing: border-box;
}
ul{
	list-style-type: none;
}
.all_center{
	min-height: 1080px;
}

</style>
