<template>
	<div class="pageNav">
		<div class="nav">
			<div class="pageWidth">
				<img :src="pageInfo.logo" alt="">
				<div class="navList">
					<ul>
						<li v-for="(item, index) in navData" :key="index">
							<router-link :to="item.path"
								:exact="item.path !== '/subject' && item.path !== '/course'">{{ item.name }}<div class="blue"></div></router-link>
						</li>
					</ul>
					<div class="all_info">
						<template v-if="token">
							<div class="register">
								<router-link to="/account_informationParents">
									<div class="registerPic">
										<img :src="userInfo.avatar" width="30px" height="30px">
									</div>
									<p class="username">{{userInfo.name}}（家长）</p>
								</router-link>

							</div>
							<div @mouseenter="showNews()" @mouseleave="hideNews()">
								<el-badge :value="getNumsLocal" class="item">
									<div><i class="el-icon-bell"></i></div>
								</el-badge>
							</div>

							<div style="font-size: 0.7292vw;cursor: pointer;margin-left:1.3542vw" @click="loginOut()">退出登录</div>
						</template>
					</div>
				</div>
				
				


			</div>

		</div>
		<div class="message_center" v-if="newsListShow == true" @mouseenter="message_over" @mouseleave="message_leave">
			<div class="center_list">
				<el-badge :value="getNumsLocal" >
					<div>消息中心</div>
				</el-badge>
				<router-link :to="{ path: '/message_center_parents' }">
					<div>更多</div>
				</router-link>
			</div>
			<div class="item" v-for="(item,index) in msgList" :key="index" @click="showMsg(item)" @mouseenter="hoverMsg(item)"
				style="cursor: pointer;">
				<div class="flex">
					<div class="pic_dot">
						<img
							:src="(item.msgType == 10 || item.msgType == 1) ? item.avatar : require('@/assets/students/a22.png')">
						<div class="redDot" v-if="item.isRead == false && item.msgNum==0"></div>
						<div class="redDotNum" v-if="item.msgNum > 0 && item.isRead == false">{{item.msgNum}}</div>
						
					</div>

					<div class="right">
						<div class="right-top">
							<div class="biaoti" v-if="item.messageType">{{item.messageType.desc}}</div>
							<div class="new_time">{{$moment(item.sendTime,'YYYY-MM-DD HH:mm:ss').fromNow()}}</div>
						</div>
						<div class="new_con"><span class="msgContent">{{item.msgContent}}</span> <span
								@click="jumpDetail(item)"
								v-if="item.msgType==3 || item.msgType==4 || item.msgType==15 || item.msgType==14 || item.msgType==2">点击查看</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="allMsgList.length > 5" style="text-align: center; font-size: 40px; font-weight: bold;">
				<router-link :to="{ path: '/message_center_parents' }">
					<div>...</div>
				</router-link>
			</div>
		</div>
		<div class="teacher">
			<el-dialog title="" :visible.sync="dialogTeacherMessage" width="64.5vw">
				<div class="dialog_message">
					<div class="dialog_left">
						<img :src="chatInfo.avatar">
						<div class="teacher_name">{{chatInfo.name}}</div>
						<div class="class">{{chatInfo.grade}}{{chatInfo.aclass}}</div>
						<p v-if="chatType == 1">{{chatInfo.teacherBriefly}}</p>
					</div>
					<div class="dialog_right">
						<div id="dialog_bottom" style="height: 32.8vw;overflow: auto;" ref="scrollContainer">
							<template v-for="(item) in chatData">

								<div class="teacher_message" v-if="item.role == 1">
									<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
										<img :src="chatInfo.avatar">
										<div>{{ chatInfo.name }}</div>
									</div>
									<div class="message">
										{{item.content}}
										<p class="message_time">
											{{item.sendTime}}
										</p>
										<!-- <p class="message_time">
											{{$moment(item.sendTime,'YYYY-MM-DD HH:mm:ss').fromNow()}}
										</p> -->
									</div>
								</div>
								<div class="student_message" v-if="item.role == 0">
									<div class="message">
										{{item.content}}
										<p class="message_time">
											{{item.sendTime}}
										</p>
										<!-- <p class="message_time">
											{{$moment(item.sendTime,'YYYY-MM-DD HH:mm:ss').fromNow()}}
										</p> -->
									</div>
									<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-left: 8px;">
										<img :src="userInfo.avatar">'
										<div style="font-size: 12px;">{{ userInfo.name }}</div>
									</div>
								</div>
							</template>

						</div>

						<div class="form_table">
							<!-- <el-form ref="form" :model="messageform">
								<el-form-item>
									<el-input type="textarea" v-model="messageform.message" resize="none" rows='4'></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="messageSubmit()">发送</el-button>
								</el-form-item>
							</el-form> -->

							<div class="form-message-input">
								<input class="input" v-model="messageform.message" @keydown="sendMessage($event)" />
								<el-button type="primary" @click="messageSubmit()">发送</el-button>
							</div>
						</div>

					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		name: "pageNav",
		data() {
			return {
				newsListShow: false,
				navData: [
					// {
					// 	text: "/homeParents",
					// 	url: "首页"
					// },
					// {
					// 	text: "/comprehensiveParents",
					// 	url: "综合测评"
					// },
					// {
					// 	text: "/encyclopaediaParents",
					// 	url: "知能百科"
					// },
					// {
					// 	text: "/studyParents",
					// 	url: "学习中心"
					// },
					// {
					// 	text: "/questionnaireParents",
					// 	url: "调查问卷"
					// },
					// {
					// 	text: "/activityParents",
					// 	url: "活动列表"
					// },
					// {
					// 	text: "/psychologyParents",
					// 	url: "心理咨询"
					// },
					// {
					// 	text: "/homeVisitingParents",
					// 	url: "家访预约"
					// }
				],
				token: '',
				userInfo: '',
				msgNum: 0,
				unReadList: [],
				msgList: [],
				allMsgList: [],
				chatInfo: {},
				chatData: [],
				userInfo: {},
				chatType: 0,
				messageform: {
					acceptId: 0,
					message: '',
				},
				dialogTeacherMessage: false,
				getNumsLocal: this.getNums
			}
		},
		props: {
			getNums: {
				type: Number,
				default: () => 0
			}
		},
		watch: {
			"$store.state.navData"(newVal) {
				this.navData = newVal[0] ? newVal[0].children : [];
				this.getActiveIndex();
			},
			msgNum: {
				handler(newVal) {
					this.$emit('getNum', newVal)
				},
				deep: true,
				immediate: true,
			},
			getNums(newValue, oldValue) {
				this.getNumsLocal = newValue
			}

		},
		computed: {
			pageInfo() {
				return this.$store.state.pageInfo
			}
		},
		methods: {
			sendMessage(event) {
				// 如果按得是回车
				if (event.keyCode == 13) {
					this.messageSubmit();
				}
			},
			jumpDetail(item) {
				console.log(item);
				if (item.msgType == 3) {
					this.readMsg(item).then(res => {
						if (this.$route.path != '/activityParents') {
							this.$router.replace({
								path: '/activityParents'
							})
						}

					});

				} else if (item.msgType == 4) {
					this.readMsg(item).then(res => {
						if (this.$route.path != '/study_listParents') {
							this.$router.replace({
								path: '/study_listParents',
								query:{
									infoId:item.businessId
								}
							})
						}
					
					});
				} else if (item.msgType == 15) {
					console.log(item.businessId);
					this.readMsg(item).then(res => {
						if (this.$route.path != '/studyParents') {
							this.$router.replace({
								path: '/study_listParents',
								query: {
									infoId: item.businessId
								}
							})
						}

					});
				} else if (item.msgType == 14) {
					this.readMsg(item).then(res => {
						if (this.$route.path != '/homeVisitingParents') {
							this.$router.replace({
								path: '/homeVisitingParents',
							})
						}

					});
				} else if (item.msgType == 2) {
					if (this.$route.path != '/personal_centerParents') {
						this.$router.replace({
							path: '/personal_centerParents',
						})
					}

				}
			},
			hoverMsg(item) {
				// console.log(item);
				let arr = ['0', '5', '6', '7', '8', '9', '12', '13'];
				if (arr.indexOf(item.msgType) != -1) {
					// this.readMsg(item);
				}
			},
			readMsg(item) {
				return new Promise((resolve, reject) => {
					this.$request({
						url: "systemMessage/messageId",
						method: "get",
						params: {
							messageId: item.id,
						},
						success: res => {
							this.getMsgList();
							this.getMsgCount();
							resolve(res)
						}
					})
				})
			},
			scrollToBottom() {
				this.$nextTick(() => {
					const container = this.$refs.scrollContainer;
					console.log(container);
					if (container) {
						setTimeout(() => {
							if (this.$refs.scrollContainer && this.$refs.scrollContainer.scrollHeight) {
								// 执行操作
								container.scrollTop = container.scrollHeight;
							}
							
						}, 200)
					}
				});
			},
			showMsg(item, type) {
				if (!type) {
					if (item.msgType == 1 || item.msgType == 10) {
						this.readMsg(item);
						this.getChatPeo(item.createUser);
						this.getChatContent(item.createUser);
						this.messageform.acceptId = item.createUser;
						this.dialogTeacherMessage = true;
					}
				} else {
					console.log(item);
					this.getChatPeo(item.userId);
					this.getChatContent(item.userId);
					this.messageform.acceptId = item.userId;

					this.dialogTeacherMessage = true;
				}

			},
			getChatContent(userId) {
				this.$request({
					url: `systemMessage/chatContent/${userId}`,
					method: "get",
					success: res => {
						this.chatData = res.data.data;
						this.scrollToBottom();
					}
				})
			},
			getChatPeo(userId) {
				this.$request({
					url: `systemMessage/studentAndTeacherChatInfo/${userId}`,
					method: "get",
					success: res => {
						this.chatInfo = res.data.data[1];
					}
				})
			},
			messageSubmit() {
				if (this.messageform.message.replace(/ /g, "") == "") {
					this.$message("请输入要发送的消息")
					return;
				}

				this.$request({
					url: "systemMessage/sendMess",
					method: "post",
					params: this.messageform,
					success: res => {
						this.messageform.message = '';
						this.getChatContent(this.messageform.acceptId);
						this.scrollToBottom();
					}
				})
			},
			message_leave() {
				this.newsListShow = false;
			},
			message_over() {
				clearTimeout(this.hideNews_timer);
				this.newsListShow = true
			},
			hideNews() {
				clearTimeout(this.hideNews_timer);
				this.hideNews_timer = setTimeout(() => {
					this.newsListShow = false
				}, 1000)
			},
			getMsgCount() {
				this.$request({
					url: "systemMessage/findUnreadMessageSize",
					method: "get",
					success: res => {
						this.msgNum = res.data.data;
						this.getNumsLocal = res.data.data;
					}
				})
			},
			getMsgList() {
				this.$request({
					url: "systemMessage/findUnreadMessageList",
					method: "get",
					errorUnShow: true,
					success: res => {
						this.allMsgList = res.data.data
						if (res.data.data.length > 5) {
							this.msgList = res.data.data.splice(0, 5);
						} else {
							this.msgList = res.data.data
						}
						
					},
					fail: error => {
						this.msgList = [];
						this.allMsgList = []
					}
				})
			},
			loginOut() {
				this.$message({
					message: '退出成功！',
					type: 'success'
				});
				localStorage.clear();
				this.$router.replace({
					path: '/'
				})
			},
			showNews() {
				if (this.newsListShow == true) {
					this.newsListShow = false
				} else {
					this.newsListShow = true
					// console.log(11111)
				}

			}
		},
		mounted() {
			this.navData = this.$store.state.navData[0] ? this.$store.state.navData[0].children : [];
			this.getMsgCount();
			this.token = localStorage.getItem("token");
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			this.getMsgList();
		},
	}
</script>

<style lang="scss" scoped>
	.msgContent {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 10vw;
		display: inline-block;
		color:black !important;
	}

	.dialog_message {
		width: 62.5vw;
		height: 41.67vw;
		border-radius: 0.52vw;
		display: flex;
		justify-content: flex-start;

		.dialog_left {
			width: 17.19vw;
			padding: 2.6vw 1.56vw;
			color: #181818;

			img {
				width: 12.5vw;
				height: 17.5vw;
			}

			.teacher_name {
				font-size: 1.46vw;
				margin-top: 2.34vw;
			}

			.class {
				font-size: 0.83vw;
				line-height: 2.6vw;
			}

			p {
				font-size: 1.04vw;
				line-height: 1.56vw;
				margin-top: 0.78vw;
			}
		}

		.dialog_right {
			width: 100%;
			background: #F7F8FA;
			padding: 2.6vw 1.56vw;

			img {
				border-radius: 50%;
			}

			.teacher_message {
				float: left;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 1.04vw;

				img {
					width: 3.33vw;
					height: 3.33vw;
					margin-right: 10px;
				}

				.message {
					font-size: 0.94vw;
					color: #333333;
					line-height: 1.6vw;
					width: 28.96vw;
					background: #FFFFFF;
					border-radius: 0.52vw;
					padding: 1.04vw;
				}

				.message_time {
					font-size: 0.63vw;
					color: #333333;
					line-height: 1.2vw;
				}
			}

			.student_message {
				float: right;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 1.04vw;

				img {
					width: 3.33vw;
					height: 3.33vw;
					margin-left: 10px;
				}

				.message {
					font-size: 0.94vw;
					color: #FFFFFF;
					line-height: 1.6vw;
					width: 28.96vw;
					background: #0098F9;
					border-radius: 0.52vw;
					padding: 1.04vw;
				}

				.message_time {
					font-size: 0.63vw;
					color: #FFFFFF;
					line-height: 1.2vw;
				}
			}

			.form_table {
				position: absolute;
				bottom: 4vw;

				.form-message-input {
					display: flex;

					.input {
						width: 32vw;
						height: 2.19vw;
						padding: 0 16px;
						background-color: #fff;
						margin-right: 1.25vw;
						border-width: 0;
						border-radius: 20vw;
						outline: none;
						resize: none;
					}

					textarea {
						width: 41.56vw;
						height: 6.25vw;
						padding: 16px;
						background: #FFFFFF;
						border-radius: 0.52vw;
						border-width: 0;
						outline: none;
						resize: none;
					}
				}

				.el-textarea {
					width: 41.56vw;
					background: #FFFFFF;
					border-radius: 0.52vw
				}

				.el-textarea__inner {
					border: 0vw;
				}

				.el-button {
					font-size: 0.73vw;
					color: #FFFFFF;
					line-height: 0.33vw;
					float: right;
				}

				.el-button--primary {
					width: 8.33vw;
					height: 2.19vw;
					background: #0098F9;
					border-radius: 1.09vw;
				}
			}
		}
	}

	.all_info {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex {
		display: flex;

		.pic_dot {
			width: 2.92vw;
			height: 2.92vw;
			border-radius: 50%;
			position: relative;
			margin-right: 0.78vw;

			.redDot {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 1vw;
				height: 1vw;
				background: #E8001A;
				border: 2px solid #FFFFFF;
				border-radius: 50%;
				z-index: 1000;
			}

			.redDotNum {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 1vw;
				height: 1vw;
				background: #E8001A;
				border: 2px solid #FFFFFF;
				border-radius: 50%;
				z-index: 1000;
				text-align: center;
				line-height: 1vw;
				font-size: 0.6vw;
				color: white;
			}
		}
	}

	.nav {
		height: 3.33vw;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-color: #fff;
	}

	.nav .pageWidth {
		width: 69.5vw;
		height: 3.33vw;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// font-size: 0.73vw;
	}

	.nav .pageWidth ul {
		// width: 34.4792vw;
		display: flex;
		margin-right: 10px;
		// align-items: center;
		// justify-content: space-between;
	}

	.nav .pageWidth>img {
		width: 6.41vw;
		height: 2.08vw;
		margin-right: 100px;

	}
	.nav .pageWidth ul li{
		// margin-right: 35px;
		margin: 10px;
		position: relative;
	}
	.nav .pageWidth ul li a {
		color: #181818;
		font-size: 0.7292vw;
	}

	.nav .pageWidth ul li a:hover {
		color: #0084FF;
	}

	.nav .pageWidth ul li .router-link-active {
		color: #0084FF;
		.blue{
			position: absolute;
			bottom: -1.2vw;
			left: 0;
			// margin-left: -.7292vw;
			width: 100%;
			height: .3125vw;
			background: #1490FF;
			border-radius: 3px;
			// display: none;
		}
	}

	.message_center {
		width: 20.31vw;
		// height: 55.94vw;
		background: #FFFFFF;
		box-shadow: 0vw 0vw 1.09vw 0vw rgba(188, 223, 255, 0.78);
		border-radius: 0.52vw;
		z-index: 100;
		position: absolute;
		top: 3.33vw;
		left: 58.8vw;
		padding: 1.04vw;
	}

	.center_list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 1.04vw 0vw 0.32vw 0vw
	}

	.center_list div:first {
		font-size: 1.04vw;
		color: #181818;
		line-height: 2.71vw;
	}

	.center_list div:last-child {
		font-size: 0.73vw;
		color: #666666;
		line-height: 2.71vw;
	}

	.message_center .item {
		width: 18.23vw;
		height: 5.05vw;
		background: #F6FAFD;
		border-radius: 0.52vw;
		margin-bottom: 0.52vw;
		padding: 1.04vw 0.63vw;
		img {
			width: 2.92vw;
			height: 2.92vw;
			margin-right: 0.78vw;
		}
	}
	.nav .pageWidth ul li .router-link-active{
        color: #0084FF;
    }
	.message_center .right {
		width: 14.06vw;
	}

	.message_center .right-top {
		display: flex;
		justify-content: space-between;
	}

	.message_center .biaoti {
		font-size: 0.94vw;
		color: #181818;
		line-height: 1.77vw;
	}

	.message_center .new_time {
		font-size: 0.63vw;
		color: #333333;
		line-height: 1.77vw;
		float: right;
	}

	.message_center .new_con {
		font-size: 0.73vw;
		color: #666666;
	}

	.message_center .new_con span {
		color: #0084FF;
		vertical-align:middle;
	}

	.register {
		display: flex;
		align-items: center;
	}

	.register .registerPic {
		width: 1.5625vw;
		height: 1.5625vw;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 1.5625vw;
			height: 1.5625vw;
			border-radius: 50%;
		}
	}

	.register .username {
		margin-left: 0.26vw;
		margin-right: .9896vw;

		font-size: 14px;
		color: #181818;
		display: inline-block;
	}

	.register a {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-icon-bell {
		// width: 18px;
		// height: 22px;
	}

	.el-badge__content.is-fixed {
		top: .7813vw;
		right: .3125vw;
	}

	.el-badge__content {
		font-size: 8px;
		height: 14px;
		line-height: 14px;
		padding: 0px 4px;
	}

	.el-icon-bell:before {
		font-size: 22px
	}
	.navList{
		display: flex;
		justify-content: space-between;
		flex: 1;
	}
</style>