import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || "",
		userType: localStorage.getItem("userType") || "",
		orgInfo: localStorage.getItem("orgInfo") ? JSON.parse(localStorage.getItem("orgInfo")) : {},
		userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {},
		navData: localStorage.getItem("navData") ? JSON.parse(localStorage.getItem("navData")) : [],
		pageInfo: localStorage.getItem("pageInfo") ? JSON.parse(localStorage.getItem("pageInfo")) : {},
	},
	getters: {
	},
	mutations: {
		setToken(state, data){
			state.token = data;
		},
		setUserType(state, data){
			state.userType = data;
		},
		setOrgInfo(state, data){
			state.orgInfo = Object.assign({}, state.orgInfo, data);
		},
		setUserInfo(state, data){
			state.userInfo = Object.assign({}, state.userInfo, data);
		},
		setNavData(state, data){
			state.navData = data;
			localStorage.setItem("navData", JSON.stringify(data));
		},
		setPageInfo(state, data) {
			state.pageInfo = data;
			localStorage.setItem("pageInfo", JSON.stringify(data));
		}
	},
	actions: {
	},
	modules: {
	}
})