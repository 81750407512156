<template>
  <div class="bottomNav">
    <div class="bottomHurdle">
        <p>地址：{{pageInfo.address}}</p>
        <p>备案号：{{pageInfo.recordNumber}} © 版权所有：{{pageInfo.reserved}} 隐私条款</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
		pageInfo() {
			return this.$store.state.pageInfo
		}
	},
}
</script>

<style lang="scss" scoped>
    .bottomHurdle{
        width: 100%;
        height: 6.042vw;
        background-color: #002257;
        text-align: center;
        padding-top: 1.927vw;
        color: #fff;
        font-size: 0.729vw;
    }
    .bottomHurdle p{
        margin-bottom: 0.5vw;
    }

</style>