const get_date_ago = function(timestamp) {
	// 获取当前时间的时间戳
	var now = Date.now();

	// 计算时间差（以毫秒为单位）
	var distance = Math.abs(timestamp - now);

	// 将时间差转换为天、小时、分钟和秒
	var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);

	let title = `${seconds}秒`;
	
	if (minutes > 0) {
		title = `${minutes}分${title}`
	}
	if (hours > 0) {
		title = `${hours}小时${title}`
	}
	if (days > 0) {
		title = `${days}天${title}`
	}
	return {
		title,
		options: {
			days: days,
			hours: hours,
			minutes: minutes,
			seconds: seconds
		},
		distance
	}
	// 返回时间差
	return {

	};
};
export default get_date_ago